import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import {
  GetPolicyTuningDiagnostics,
  GetPolicyTuningDiagnosticsParams,
  GetPolicyTuningDiagnosticsResponse,
} from "../../../../api/fetcher";
import { components } from "../../../../api/schema";
import { SELECTED_CONTAINER_KEY } from "../ContainerFilter";
import DiagnosticsChart from "./DiagnosticsChart";
import { diagnosticChartElements, EventPoint } from "./utils";

type DiagnosticPoint = components["schemas"]["UtilsDiagnosticDataPoint"];
const { queryFn, queryKey } = GetPolicyTuningDiagnostics();

const INITIAL_PROPERTIES = diagnosticChartElements.reduce((acc, curr) => {
  if (curr === "auto") {
    acc[curr] = undefined;
  } else {
    acc[curr] = 0;
  }
  return acc;
}, {} as EventPoint);

const DiagnosticsContainer = ({
  name,
  namespace,
  endDate,
  period,
  startDate,
  selectedViewPeriod,
  emptyEventArray,
  forcedIsLoading,
}: GetPolicyTuningDiagnosticsParams & {
  selectedViewPeriod: string;
  emptyEventArray: EventPoint[] | undefined;
  forcedIsLoading?: boolean;
}) => {
  const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [parsedData, setParsedData] = useState<EventPoint[] | undefined>(undefined);

  const { data, isLoading } = useQuery<GetPolicyTuningDiagnosticsResponse, Error>({
    queryKey: [queryKey, name, namespace, endDate, period, startDate, selectedContainer, emptyEventArray],
    queryFn: () => {
      return queryFn({ name, namespace, endDate, period, startDate, container: selectedContainer });
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (emptyEventArray && emptyEventArray.length > 0) {
      // Filter out the events that have no data
      const eventsPoints = data?.diagnosticEventsSeries?.filter((entry) => {
        return diagnosticChartElements.some((element) => {
          const value = entry[element as keyof DiagnosticPoint];
          return value && Number(value) > 0;
        });
      });

      // Convert the timestamp to epoch
      const eventsPointsWithEpochTimestamp = eventsPoints?.map((entry) => ({
        ...entry,
        timestamp: dayjs(entry.timestamp).unix(),
      }));

      const chartData = emptyEventArray.map((entry) => ({
        ...INITIAL_PROPERTIES,
        hasEvents: true,
        timestamp: entry.timestamp,
      }));

      eventsPointsWithEpochTimestamp?.forEach((entry) => {
        // find the closest index
        let index = 0;
        const eventTimestamp = entry.timestamp;

        switch (true) {
          case eventTimestamp < Number(chartData[0].timestamp):
            index = 0;
            break;
          case eventTimestamp > Number(chartData[chartData.length - 1].timestamp):
            index = chartData.length - 1;
            break;

          default:
            for (let i = 1; i < chartData.length; i++) {
              if (eventTimestamp < Number(chartData[i].timestamp)) {
                index = i;
                break;
              }
            }
        }

        // Add the data to the emptyEventArray
        diagnosticChartElements.forEach((element) => {
          switch (true) {
            case element === "auto":
              chartData[index][element] = chartData[index][element] || entry[element as keyof DiagnosticPoint] ? 1 : 1;
              break;
            case !!entry[element as keyof DiagnosticPoint]:
              chartData[index][element] = chartData[index][element]
                ? Number(chartData[index][element]) + Number(entry[element as keyof DiagnosticPoint])
                : entry[element as keyof DiagnosticPoint];
              break;
          }
        });
      });

      setParsedData(chartData);
    }
  }, [data]);

  return (
    <DiagnosticsChart
      data={parsedData}
      isLoading={isLoading || forcedIsLoading}
      selectedViewPeriod={selectedViewPeriod}
      includedElements={diagnosticChartElements}
    />
  );
};

export default DiagnosticsContainer;
